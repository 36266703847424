<template>
  <el-main>
    <el-form class="el-form-search" label-width="90px">
      <el-form-item label="用户昵称：">
        <el-input size="small" v-model="searchForm.nickname" placeholder="请输入商品名称"></el-input>
      </el-form-item>
      <el-form-item label="手机号：">
        <el-input size="small" v-model="searchForm.mobile" placeholder="请输入商品名称"></el-input>
      </el-form-item>
      <el-form-item label="所属区域：">
        <el-cascader
          v-model="searchForm.area_id"
          :props="{ value: 'id', label: 'name', children: '_child' }"
          placeholder="请选择或搜索"
          clearable
          filterable
          :options="areaList"
          size="small"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="注册日期：">
        <el-date-picker
          v-model="searchForm.start_time"
          size="small"
          type="daterange"
          value-format="timestamp"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="审核状态：">
        <el-select v-model="searchForm.status" size="small">
          <el-option label="全部" value=""></el-option>
          <el-option label="待审核" :value="1"></el-option>
          <el-option label="审核通过" :value="2"></el-option>
          <el-option label="审核驳回" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button size="small" @click="search" type="primary">搜索</el-button>
        <el-button size="small" @click="searchClear" type="text">清除搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" row-key="id" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="name" label="推广员信息" align="center">
        <template v-slot="{ row }">
          <userInfo :name="row.nickname" :avatar="row.avatar"></userInfo>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="推广员姓名" align="center"></el-table-column>
      <el-table-column prop="mobile" label="推广员电话" align="center"></el-table-column>
      <el-table-column prop="area" label="所属区域" align="center"></el-table-column>
      <el-table-column prop="create_time" label="提交时间" align="center">
        <template v-slot="{ row }">
          {{ getDateformat(row.create_time) }}
        </template>
      </el-table-column>
      <el-table-column prop="status" label="审核状态" align="center">
        <template v-slot="{ row }">
          {{ row.status == 0 ? '待审核' : row.status == 1 ? '审核通过' : '审核驳回' }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button v-if="scope.row.status == 0" @click="audit(scope.row)" type="text" size="small">审核</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="审核" :visible.sync="dislogAudit" width="600px">
      <el-form class="auditForm">
        <el-form-item label="请选择审核结果："></el-form-item>
        <el-form-item>
          <el-radio-group v-model="status">
            <el-radio :label="1">通过</el-radio>
            <el-radio :label="2">驳回</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="status == 2" label="请添加驳回原因：" prop="reason">
          <el-input type="textarea" :rows="3" placeholder="请输入内容" v-model="content"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dislogAudit = !1">取 消</el-button>
        <el-button type="primary" @click="handleAudit">确 定</el-button>
      </span>
    </el-dialog>
    <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import _ from 'lodash';
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
import userInfo from '@/components/userInfo';
export default {
  components: {
    Paging,
    userInfo,
  },
  data() {
    return {
      dislogAudit: !1,
      searchForm: {
        page: 1,
        rows: 10,
        nickname: '',
        mobile: '',
        province_id: '',
        city_id: '',
        district_id: '',
        start_time: '',
        area_id: [],
        end_time: '',
        status: '',
      },
      total_number: 0,
      list: [],
      areaList: [],
      status: 1,
      content: '',
      id: '',
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getDateformat(val) {
      return getDateformat(val);
    },
    search() {
      this.searchForm.page = 1;
      this.searchForm.rows = 10;
      this.getList();
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      this.getList();
    },
    searchClear() {
      this.searchForm = {
        page: 1,
        rows: 10,
        nickname: '',
        mobile: '',
        province_id: '',
        city_id: '',
        district_id: '',
        start_time: '',
        end_time: '',
        area_id: [],
        status: '',
      };
      this.getList();
    },
    getList() {
      let searchForm = _.cloneDeep(this.searchForm);
      if (searchForm.start_time) {
        searchForm.end_time = searchForm.start_time[1] / 1000;
        searchForm.start_time = searchForm.start_time[0] / 1000;
      }
      searchForm.province_id = searchForm.area_id[0];
      searchForm.city_id = searchForm.area_id[1];
      searchForm.district_id = searchForm.area_id[2];
      delete searchForm.area_id;
      this.$axios.post(this.$api.O2O.distributionAuditList, searchForm).then(res => {
        if (res.code == 0) {
          this.list = res.result.list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    audit(row) {
      this.dislogAudit = !0;
      this.id = row.id;
    },
    handleAudit() {
      if (this.status == 2 && !this.content) {
        return this.$message.warning('请填写审核原因');
      }
      this.$axios
        .post(this.$api.O2O.distributionAudit, {
          id: this.id,
          status: this.status,
          content: this.content,
        })
        .then(res => {
          if (res.code == 0) {
            this.dislogAudit = !1;
            this.$message.success('处理成功');
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  .auditForm {
    .el-form-item {
      margin: 0;
    }
  }
}
</style>